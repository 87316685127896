/** @jsx jsx */
import { Fragment } from "react"
import { jsx } from "theme-ui"
import { hidden } from "~components/Styles/utils"

const icons = {
  pieNoSteam: {
    shape: (
      <g transform="translate(0.000000,502.000000) scale(0.100000,-0.100000)">
        {/* <path d="M3939.3,4789.7c-371.9-118.9-573.2-448.6-511.9-837.8c40.3-251.1,130.4-375.7,529.1-726.6c130.4-115,132.3-118.9,132.3-210.9c0-61.3-17.3-128.4-47.9-189.8c-44.1-92-193.6-260.7-285.6-322.1l-46-30.7l84.3-143.8c46-80.5,93.9-143.8,107.4-141.9c40.3,9.6,224.3,172.5,312.5,279.9c216.6,256.9,285.6,569.4,174.5,786c-57.5,113.1-136.1,197.5-322.1,343.2c-245.4,195.5-306.7,308.6-289.5,540.6c11.5,172.5,120.8,289.5,308.6,335.5c53.7,13.4,55.6,15.3,42.2,113.1C4094.6,4862.5,4109.9,4845.3,3939.3,4789.7z" />
        <path d="M4995.6,4791.6c-393-126.5-594.3-465.9-513.8-868.4c44.1-226.2,155.3-391.1,406.4-592.4c218.6-176.4,245.4-212.8,245.4-318.2c0-151.4-116.9-345.1-279.9-467.8c-46-34.5-84.4-70.9-84.4-80.5c0-26.8,155.3-281.8,172.5-281.8c30.7,0,205.1,149.5,301,258.8c118.8,132.3,207,304.8,233.9,460.1c47.9,260.7-38.3,431.3-335.5,671c-97.8,78.6-201.3,172.5-228.1,209c-53.7,70.9-105.4,214.7-105.4,297.1c0,170.6,128.4,337.4,295.2,385.3c67.1,17.3,86.3,32.6,80.5,55.6c-5.8,17.3-15.4,93.9-21.1,170.6c-7.7,84.4-21.1,138-36.4,136.1C5114.4,4826.1,5055,4810.8,4995.6,4791.6z" />
        <path d="M6088.3,4806.9c-207-47.9-433.3-230-519.5-416c-61.3-130.4-59.4-463.9,1.9-617.3c61.3-155.3,162.9-276.1,373.8-450.5c105.4-84.4,201.3-174.5,216.6-201.3c72.9-134.2-17.2-354.7-222.4-540.6l-130.4-116.9l86.3-141.9c46-76.7,90.1-139.9,95.9-139.9c7.7,0,59.4,36.4,116.9,82.4c141.9,107.4,283.7,283.7,356.6,435.2c53.7,111.2,59.4,143.8,59.4,306.7c0,260.7-23,297.1-440.9,659.5c-155.3,138-220.5,256.9-220.5,412.2c0,191.7,109.3,337.4,295.2,387.3l74.8,19.2l-13.4,116.9c-5.8,65.2-11.5,141.9-11.5,170.6C6207.2,4831.8,6201.4,4833.7,6088.3,4806.9z" /> */}
        <path d="M4529.7,1624.6c-853.1-65.2-1690.9-341.2-2319.7-764.9c-462-310.6-855-722.7-1056.3-1100.4L1079-382.6l-143.8-21.1c-529.1-78.6-904.9-579-824.3-1098.5c21.1-149.5,111.2-352.8,209-485c38.3-51.8,164.9-373.8,396.8-1006.5c185.9-511.9,360.4-972,385.3-1025.6c149.5-299.1,414.1-613.5,600-711.2l105.4-55.6h3182.3h3182.4l95.8,46c189.8,92,446.7,396.8,607.7,720.8c38.3,74.8,216.6,544.5,398.8,1044.8c270.3,738.1,345.1,925.9,396.8,987.3c36.4,42.2,95.9,138,132.3,210.9c287.6,590.5-97.8,1284.4-764.9,1374.6l-164.9,23L8789.5-235c-276.1,473.5-722.7,910.6-1249.9,1221.2C6699.9,1478.9,5626.3,1707,4529.7,1624.6z M5632.1,1250.7C7012.4,1072.5,8170.3,338.2,8593.9-628c40.2-90.1,76.7-149.5,92-143.8c11.5,3.8,97.8,11.5,187.9,15.4c233.9,15.3,335.5-21.1,488.9-174.5c88.2-88.2,124.6-143.8,151.5-222.4c34.5-101.6,46-322.1,19.2-364.2c-17.3-28.8-9050.5-26.8-9069.7,1.9c-24.9,42.2-13.4,258.8,21.1,362.3c26.8,78.6,63.3,134.2,151.4,222.4c153.4,153.4,255,189.8,490.8,176.4l166.8-9.6l63.3,153.4c88.2,218.6,299.1,521.5,504.2,728.5C2733.4,999.6,4188.5,1436.7,5632.1,1250.7z M9293.7-1897.1c0-7.7-157.2-448.6-350.8-975.8c-381.5-1042.9-437.1-1161.7-653.7-1399.5c-61.3-67.1-136.1-134.2-166.8-149.5c-80.5-42.2-6184.5-42.2-6265,0c-113.1,57.5-333.6,337.4-444.8,563.6C1368.5-3768.2,686-1920.1,686-1891.3c0,5.7,1936.2,9.6,4303.8,9.6C7357.4-1881.8,9293.7-1889.4,9293.7-1897.1z" />
        <path d="M3680.5,399.6c-187.9-84.4-387.3-343.2-387.3-500.4c0-136.1,113.1-166.8,285.6-82.4C3776.3-83.6,3945,136.9,3945,299.9C3945,434.1,3841.5,472.4,3680.5,399.6z" />
        <path d="M6134.3,407.2c-57.5-40.3-55.6-153.4,3.8-272.2c55.6-111.2,232-279.9,341.2-329.7c324-147.6,362.3,180.2,55.6,465.8C6375.9,420.6,6224.4,472.4,6134.3,407.2z" />
        <path d="M4926.6,125.4c-140-147.6-151.5-542.5-21.1-724.7c82.4-116.9,199.4-84.3,272.2,74.8c47.9,107.4,57.5,391.1,17.3,508C5133.6,154.2,5012.8,217.4,4926.6,125.4z" />
      </g>
    ),
    viewBox: `0 0 1000 1000`,
  },
  pie: {
    shape: (
      <g transform="translate(0.000000,502.000000) scale(0.100000,-0.100000)">
        <path d="M3939.3,4789.7c-371.9-118.9-573.2-448.6-511.9-837.8c40.3-251.1,130.4-375.7,529.1-726.6c130.4-115,132.3-118.9,132.3-210.9c0-61.3-17.3-128.4-47.9-189.8c-44.1-92-193.6-260.7-285.6-322.1l-46-30.7l84.3-143.8c46-80.5,93.9-143.8,107.4-141.9c40.3,9.6,224.3,172.5,312.5,279.9c216.6,256.9,285.6,569.4,174.5,786c-57.5,113.1-136.1,197.5-322.1,343.2c-245.4,195.5-306.7,308.6-289.5,540.6c11.5,172.5,120.8,289.5,308.6,335.5c53.7,13.4,55.6,15.3,42.2,113.1C4094.6,4862.5,4109.9,4845.3,3939.3,4789.7z" />
        <path d="M4995.6,4791.6c-393-126.5-594.3-465.9-513.8-868.4c44.1-226.2,155.3-391.1,406.4-592.4c218.6-176.4,245.4-212.8,245.4-318.2c0-151.4-116.9-345.1-279.9-467.8c-46-34.5-84.4-70.9-84.4-80.5c0-26.8,155.3-281.8,172.5-281.8c30.7,0,205.1,149.5,301,258.8c118.8,132.3,207,304.8,233.9,460.1c47.9,260.7-38.3,431.3-335.5,671c-97.8,78.6-201.3,172.5-228.1,209c-53.7,70.9-105.4,214.7-105.4,297.1c0,170.6,128.4,337.4,295.2,385.3c67.1,17.3,86.3,32.6,80.5,55.6c-5.8,17.3-15.4,93.9-21.1,170.6c-7.7,84.4-21.1,138-36.4,136.1C5114.4,4826.1,5055,4810.8,4995.6,4791.6z" />
        <path d="M6088.3,4806.9c-207-47.9-433.3-230-519.5-416c-61.3-130.4-59.4-463.9,1.9-617.3c61.3-155.3,162.9-276.1,373.8-450.5c105.4-84.4,201.3-174.5,216.6-201.3c72.9-134.2-17.2-354.7-222.4-540.6l-130.4-116.9l86.3-141.9c46-76.7,90.1-139.9,95.9-139.9c7.7,0,59.4,36.4,116.9,82.4c141.9,107.4,283.7,283.7,356.6,435.2c53.7,111.2,59.4,143.8,59.4,306.7c0,260.7-23,297.1-440.9,659.5c-155.3,138-220.5,256.9-220.5,412.2c0,191.7,109.3,337.4,295.2,387.3l74.8,19.2l-13.4,116.9c-5.8,65.2-11.5,141.9-11.5,170.6C6207.2,4831.8,6201.4,4833.7,6088.3,4806.9z" />
        <path d="M4529.7,1624.6c-853.1-65.2-1690.9-341.2-2319.7-764.9c-462-310.6-855-722.7-1056.3-1100.4L1079-382.6l-143.8-21.1c-529.1-78.6-904.9-579-824.3-1098.5c21.1-149.5,111.2-352.8,209-485c38.3-51.8,164.9-373.8,396.8-1006.5c185.9-511.9,360.4-972,385.3-1025.6c149.5-299.1,414.1-613.5,600-711.2l105.4-55.6h3182.3h3182.4l95.8,46c189.8,92,446.7,396.8,607.7,720.8c38.3,74.8,216.6,544.5,398.8,1044.8c270.3,738.1,345.1,925.9,396.8,987.3c36.4,42.2,95.9,138,132.3,210.9c287.6,590.5-97.8,1284.4-764.9,1374.6l-164.9,23L8789.5-235c-276.1,473.5-722.7,910.6-1249.9,1221.2C6699.9,1478.9,5626.3,1707,4529.7,1624.6z M5632.1,1250.7C7012.4,1072.5,8170.3,338.2,8593.9-628c40.2-90.1,76.7-149.5,92-143.8c11.5,3.8,97.8,11.5,187.9,15.4c233.9,15.3,335.5-21.1,488.9-174.5c88.2-88.2,124.6-143.8,151.5-222.4c34.5-101.6,46-322.1,19.2-364.2c-17.3-28.8-9050.5-26.8-9069.7,1.9c-24.9,42.2-13.4,258.8,21.1,362.3c26.8,78.6,63.3,134.2,151.4,222.4c153.4,153.4,255,189.8,490.8,176.4l166.8-9.6l63.3,153.4c88.2,218.6,299.1,521.5,504.2,728.5C2733.4,999.6,4188.5,1436.7,5632.1,1250.7z M9293.7-1897.1c0-7.7-157.2-448.6-350.8-975.8c-381.5-1042.9-437.1-1161.7-653.7-1399.5c-61.3-67.1-136.1-134.2-166.8-149.5c-80.5-42.2-6184.5-42.2-6265,0c-113.1,57.5-333.6,337.4-444.8,563.6C1368.5-3768.2,686-1920.1,686-1891.3c0,5.7,1936.2,9.6,4303.8,9.6C7357.4-1881.8,9293.7-1889.4,9293.7-1897.1z" />
        <path d="M3680.5,399.6c-187.9-84.4-387.3-343.2-387.3-500.4c0-136.1,113.1-166.8,285.6-82.4C3776.3-83.6,3945,136.9,3945,299.9C3945,434.1,3841.5,472.4,3680.5,399.6z" />
        <path d="M6134.3,407.2c-57.5-40.3-55.6-153.4,3.8-272.2c55.6-111.2,232-279.9,341.2-329.7c324-147.6,362.3,180.2,55.6,465.8C6375.9,420.6,6224.4,472.4,6134.3,407.2z" />
        <path d="M4926.6,125.4c-140-147.6-151.5-542.5-21.1-724.7c82.4-116.9,199.4-84.3,272.2,74.8c47.9,107.4,57.5,391.1,17.3,508C5133.6,154.2,5012.8,217.4,4926.6,125.4z" />
      </g>
    ),
    viewBox: `0 0 1000 1000`,
  },
  triangle: {
    shape: <polygon strokeWidth="1px" strokeLinejoin="round" strokeMiterlimit="10" points="14.921,2.27 28.667,25.5 1.175,25.5 " />,
    viewBox: `0 0 30 30`,
  },
  circle: {
    shape: <path d="M15,30A15,15,0,1,1,30,15,15,15,0,0,1,15,30ZM15,6.23A8.77,8.77,0,1,0,23.77,15,8.77,8.77,0,0,0,15,6.23Z" />,
    viewBox: `0 0 30 30`,
  },
  arrowUp: {
    shape: (
      <Fragment>
        <path d="M28.74,20.81H1.26a1.26,1.26,0,0,1-1-2L14.16.5a1.25,1.25,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,18.8a1.25,1.25,0,0,1-1,2ZM3.81,18.29H26.22L15.16,3.37Z" />
        {` `}
        <path d="M28.74,42H1.26a1.28,1.28,0,0,1-1.13-.71A1.26,1.26,0,0,1,.26,40l13.9-18.29a1.28,1.28,0,0,1,1-.5h0a1.24,1.24,0,0,1,1,.51L29.75,40a1.26,1.26,0,0,1,.12,1.32A1.28,1.28,0,0,1,28.74,42ZM3.81,39.47H26.22L15.16,24.55Z" />
      </Fragment>
    ),
    viewBox: `0 0 30 42`,
  },
  upDown: {
    shape: (
      <Fragment>
        <path d="M28.74,44.58a1.28,1.28,0,0,1-1-.51L15.16,27.13l-12.89,17a1.26,1.26,0,1,1-2-1.53l13.9-18.29a1.34,1.34,0,0,1,1-.5,1.24,1.24,0,0,1,1,.51L29.75,42.56a1.27,1.27,0,0,1-1,2Z" />
        <path d="M14.83,20.82h0a1.28,1.28,0,0,1-1-.52L.25,2a1.27,1.27,0,0,1,2-1.51L14.84,17.45,27.73.5a1.26,1.26,0,0,1,2,1.53L15.84,20.32A1.28,1.28,0,0,1,14.83,20.82Z" />
      </Fragment>
    ),
    viewBox: `0 0 30 44.58`,
  },
  box: {
    shape: (
      <path d="M28,2V28H2V2H28m.13-2H1.88A1.88,1.88,0,0,0,0,1.88V28.13A1.88,1.88,0,0,0,1.88,30H28.13A1.87,1.87,0,0,0,30,28.13V1.88A1.88,1.88,0,0,0,28.13,0Z" />
    ),
    viewBox: `0 0 30 30`,
  },
  hexa: {
    shape: (
      <polygon strokeLinejoin="round" strokeMiterlimit="10" points="27.5,21.904 15,28.809  2.5,21.904 2.5,8.095 15,1.19 27.5,8.095 " />
    ),
    viewBox: `0 0 30 30`,
  },
  cross: {
    shape: (
      <path
        strokeWidth="3px"
        d="M60.5,50l34.8-34.8c2.9-2.9,2.9-7.6,0-10.5c-2.9-2.9-7.6-2.9-10.5,0L50,39.5L15.2,4.7c-2.9-2.9-7.6-2.9-10.5,0    c-2.9,2.9-2.9,7.6,0,10.5L39.5,50L4.7,84.8c-2.9,2.9-2.9,7.6,0,10.5c1.4,1.4,3.3,2.2,5.2,2.2s3.8-0.7,5.2-2.2L50,60.5l34.8,34.8    c1.4,1.4,3.3,2.2,5.2,2.2c1.9,0,3.8-0.7,5.2-2.2c2.9-2.9,2.9-7.6,0-10.5L60.5,50z"
      />
    ),
    viewBox: `0 0 100 100`,
  },
}

type IconType = keyof typeof icons
//  "triangle" | "circle" | "arrowUp" | "upDown" | "box" | "hexa" | "cross"

type SVGProps = {
  stroke?: boolean
  color?: string | number | any
  width: number
  icon: IconType
  left: string
  top: string
  hiddenMobile?: boolean
} & React.HTMLAttributes<SVGElement>

const Svg = ({ stroke = false, color = ``, width, icon, left, top, hiddenMobile = false, ...rest }: SVGProps) => (
  <svg
    sx={{
      position: `absolute`,
      stroke: stroke ? `currentColor` : `none`,
      fill: stroke ? `none` : `currentColor`,
      display: hiddenMobile ? hidden : `block`,
      color,
      width,
      left,
      top,
    }}
    viewBox={icons[icon].viewBox}
    {...rest}
  >
    {icons[icon].shape}
  </svg>
)

export default Svg
